import { useState, useLayoutEffect } from "react";
import { CreatePrimaryToken, FetchIndividual, GetSecuredContents, getInfo, GetTemplateByID, GetServiceProvider } from "../utilities/calls";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { AiOutlineImport, AiOutlineProfile } from "react-icons/ai";
import { ProcessTemplate } from "../utilities/templates";

export default function ContactSPTemplate() {
    const [individual, setIndividual] = useState({});
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [securedContents, setSecuredContents] = useState([]);
    const [selectedContentId, setSelectedContentId] = useState();
    const { id } = useParams();
    const { templateid } = useParams();
    const navigate = useNavigate();
    const [sent, setSent] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [template, setTemplate] = useState();
    const { pathname } = useLocation();
    const [serviceProvider, setServiceProvider] = useState();

    useLayoutEffect(() => {
        sessionStorage.setItem('redirectto', pathname)
        const individualid = getInfo("IndividualID");
        FetchIndividual(individualid)
            .then((individualData) => {
                setIndividual(individualData);
            })
            .catch((error) => {
                console.error("Error fetching individual:", error);
            });
        GetServiceProvider(id).then(resp => {
            setServiceProvider(resp);
        }).catch(err => console.log('Error in get service provider', err))
        GetSecuredContents().then((resp) => {
            setSecuredContents(resp)
        }).catch(err => console.log('Error getting secured content', err));
        GetTemplateByID(templateid).then((t) => {
            setSelectedTemplate(ProcessTemplate(t));
            setTemplate(t);
        }).catch(err => console.log('Err in getting template details', err))

    }, [pathname, templateid, id]);

    const connect = () => {
        let contentid = document.getElementById('contentselect').value;
        CreatePrimaryToken(id, "", individual.id, contentid).then(() => {
            setShowAlert(true);
            setAlertText("Contact shared with Service Provider");
            setSent(true);
        }).catch((err) => {
            console.error("Error contacting Service Provider", err);
            setAlertText("Error contacting Service Provider, please try again!");
            setShowAlert(true)
        })
    }

    const cancelFn = () => {
        navigate(`/sharing`)
    }

    const handleInputChange = (e) => {
        const { value } = e.target;
        if (value === "") setSent(true);
        setSelectedContentId(value);
        setSent(false);
    };


    return <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <div className="mx-auto max-w-screen-lg form-container p-5">
            <div>
                <h1 className="form-label py-2">
                    <AiOutlineImport className="inline-icon" />
                    Share {selectedTemplate?.templateDisplayName} with {`${serviceProvider?.tags?.name?.Value || "No Name"}`}
                </h1>
            </div>

            <div className="grid grid-cols-1">

                <form className="" id="address select">

                    {securedContents.length > 0 && securedContents.filter(a => a.tags?.contenttype?.Value === selectedTemplate?.templateName).length > 0 ?
                        <select id="contentselect" defaultValue={`${selectedContentId}`} onChange={handleInputChange} className="form-input" >
                            <option value="">Select {selectedTemplate?.templateDisplayName}</option>
                            {securedContents.filter(a => a.tags?.contenttype?.Value === selectedTemplate?.templateName).sort((a, b) => { return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase()) }).map((sc, index) => {
                                return <option key={sc.id} value={sc.id} selected={index === 0}>{selectedTemplate ? sc.tags[selectedTemplate?.contentNameTag]?.Value : sc.tags?.name?.Value}</option>
                            })
                            }
                        </select>
                        : <p className="py-2">You don't have any {selectedTemplate?.templateDisplayName} yet.</p>
                    }
                    <Link className="" to={{ pathname: "/addsecurecontent/" }} state={{ contenttype: selectedTemplate?.templateName, "template": template, previousPage: pathname, searchedText: "" }} >
                        <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="Add New Address">
                            <AiOutlineProfile className="inline-icon" /> Add New {selectedTemplate?.templateDisplayName}
                        </button>
                    </Link>
                </form>

                <div class="flex justify-center items-center space-x-4 py-2 ">
                    <button onClick={connect} style={{ display: sent ? "none" : "block" }} type="submit" className="button-style positive-action">
                        Connect
                    </button>
                    {!sent ? (
                        <button onClick={cancelFn} className="button-style">Cancel</button>
                    ) : (
                        <button onClick={cancelFn} className="button-style">Close</button>
                    )}
                    <div
                        class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                        role="alert"
                        style={{ display: showAlert ? "block" : "none" }}
                    >
                        <span class="text-sm">{alertText} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}