//param title, close and save handles, locales
import { AiOutlineProfile } from "react-icons/ai";
import { AddAddress, FetchIndividual, getInfo } from "../utilities/calls";
import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { address_locales , countryLocaleList} from "../utilities/templates";
import { renderField } from "../utilities/functions";
import Tags from "./Tags";

export default function AddressForm() {

  const [sent, setSent] = useState(true);

  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [addresses, setAddresses] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    Intl.NumberFormat().resolvedOptions().locale
  );
  const [customTags,setCustomTags] = useState([])

  const [updatedaddress, updateAddress] = useState({
    name: "",
    addressee: "",
    street: "",
    city: "",
    zipcode: "",
    state: "",
    phone: "",
    email: "",
    country: "United States",
  });

  const location = useLocation(); // Get the tags from the state param

  const processForm = () => {
    var mergedaddress = {
      street: updatedaddress?.street,
      city: updatedaddress?.city,
      state: updatedaddress?.state,
      zipCode: updatedaddress?.zipCode,
      country: updatedaddress?.country,
      addressee: updatedaddress?.addressee,
      phone: updatedaddress?.phone,
      email: updatedaddress?.email,
      tags: {
        atag: {
          Name: updatedaddress?.name,
          Value: null,
          Private: false,
          Required: false,
          Editable: false,
        },
      },
    };
    const individualid = getInfo("IndividualID");

    const tags = location?.state?.tags || {};

    mergedaddress.tags = {...mergedaddress.tags, ...customTags}

    AddAddress(individualid, tags, mergedaddress)
      .then((response) => {
        //window.alert("Address added");
        setShowAlert(true);
        setAlertText("Address added!");
        const individualid = getInfo("IndividualID");
        FetchIndividual(individualid).then((individualData) => {
          setAddresses(individualData.addresses);
        }).catch((error) => {
          console.error("Error fetching individual:", error);
          setShowAlert(true);
          setAlertText("Error fetching individual");
        });
        setSent(true);
      })
      .catch((error) => {
        console.error("Error UPDATING address details:", error);
        //window.alert("Address add failed! Sorry!");
        setShowAlert(true);
        setAlertText("Address add failed! Sorry!");
      });
  };

  const handleInputChange = (e) => {
    if (e.target.name === "country") {
      handleCountryChange(e);
      return;
    }
    const { name, value } = e.target;
    updateAddress((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setSent(false);
  };

  const handleCountryChange = (e) => {
    const countryVal = countryLocaleList.find(
      (o) => o.name === e.target.value
    ).value;
    updateAddress((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
    setSent(false);

    setSelectedCountry(countryVal);
  };

  const addCustomTag = (tag, key) => {
    console.log(tag)
    var tagname = key ? key : tag.Name;
    setCustomTags((prevValues) => ({
      ...prevValues, [tagname] : tag
    }))
    setSent(false);
  } 

  const removeCustomTag = (tagName) => {
    console.log(tagName)   
    var newMap = {...customTags};
    delete newMap[tagName]
    console.log(newMap)
    setCustomTags({...newMap} );
    setSent(false);
  }

  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="py-2 form-label">
            <AiOutlineProfile className="inline-icon" />
            New Address Details
          </h1>
        </div>

        <div>
          {address_locales[selectedCountry]
            ? address_locales[selectedCountry].map((element) => {
                return renderField(element.label,element.id,element.type,updatedaddress[element.id],handleInputChange,element.options);
              })
            : address_locales["default"].map((element) => {
                return renderField(element.label,element.id,element.type,updatedaddress[element.id],handleInputChange,element.options);
              })}
        </div>
        <div className="py-5"><Tags saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag}/></div>  
        <section className="grid grid-cols-12 gap-20 py-2">
          <button style={{width: "fit-content",display: sent ? "none" : "block",}} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="button-style positive-action">
            ADD
          </button>

          {!sent ? (
            <Link to={location.state.previousPage} state={{ request: location.state.request, addresses: addresses, previousPage: "/requests",text:location.state.searchedText, "contenttype": location.state.contenttype }} >
              <button className="button-style">Cancel</button>
            </Link>
          ) : (
            <Link to={location.state.previousPage} state={{ request: location.state.request, addresses: addresses, previousPage: "/requests",text:location.state.searchedText, "contenttype": location.state.contenttype}}>
              <button className="button-style">Close</button>
            </Link>
          )}
          <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }}>
            <span class="text-sm">{alertText} </span>
          </div>
        </section>
      </div>
    </div>
  );
}

