import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeModal = ({ isOpen, onClose, qrValue }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">
        <div className='max-w-[500px] rounded-lg border border-gray-700 bg-gray-800 flex flex-col gap-2 relative'>
            <div className="py-3 px-2">
                <button className="absolute top-2 right-1 text-lg font-normal text-gray-400  -translate-x-2 -translate-y-1" onClick={onClose}>X</button>
            
        <h2 className="form-label mb-2">QR Code</h2>
        <QRCodeCanvas value={qrValue} size={200} />
        <button className="close-button mt-2 items-center inline-flex justify-center px-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-red-500" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
    </div>
  );
};

export default QRCodeModal;
