// src/components/Sharing.js
import React, { useEffect, useState } from "react";
import {  GetAddress, GetShares, GetSharesWith, getInfo, DeleteSecondaryToken, DeletePrimaryToken, GetRequestDetails, CreateSecondaryToken, GetServiceProvider, GetBeneficiary, GetSecuredContent, GetSharedSecuredContent, GetTemplateByID, GetTemplatesByServiceProviderID } from "../utilities/calls";
import "./Sharing.css";
import Login from "./Login";
import DeleteModal from "./DeleteModal";
import AddressBox from "./AddressBox";
import ContentBox from "./SecuredContentBox";
import {  FirstMatchedTemplate, ProcessTemplate } from "../utilities/templates";


const IndividualShares = () => {
    const [addresses, setAddresses] = useState([]);
    const [addressesSharedWithBene, setAddressesSharedWithBene] = useState([]);
    const [addressesSharedWithSP, setAddressesSharedWithSP] = useState([]);

    const [shares, setShares] = useState([]);
    const [spShares, setSpShares] = useState([]);
    const [sharesWithBen, setSharesWithBen] = useState([]);
    const [userId, setUserId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const [currShare, setCurrShare] = useState();
    const [sP, setSP] = useState();
    const [refBens, setRefBens] = useState({});
    const [rNote, setRNote] = useState({});
    const [benList, setBenList] = useState({});
    const [spList, setSpList] = useState({});
    const [securedContents, setSecuredContents] = useState([]);
    let spIds = [];
    const [contenttype, setContenttype] = useState("");
    const [templates, setTemplates] = useState([{Name: "Addresses", Tags: { "DisplayName": {
        "Name": "DisplayName",
        "Value": "Addresses",
        "Private": false,
        "Required": true,
        "Editable": false
}}}]);


    useEffect(() => {
        setUserId(getInfo("UserID"));
        setShowDeleteDlg(false);
        let defaultSP = getInfo('DefaultServiceProvider');
        if(defaultSP) {
            spIds.push(defaultSP);
            getTemplates(defaultSP);
        }
    }, []);

    const getTemplates = (spid) => {
        GetTemplatesByServiceProviderID(spid).then((templateIdsArr) => {
            templateIdsArr.forEach((id) => {
                if(templates.find(t => t.id === id)){
                    return;
                }
                GetTemplateByID(id).then((template) => {
                    setTemplates((templates) =>   [...templates, template]);
                }).catch(err => console.log('Error fetching templates for spid ', spid, err));
            })
        }).catch(err => console.log('Error fetching templates for spid ', spid, err));
    }


    useEffect(() => {
        const individualid = getInfo("IndividualID");

        GetSharesWith(individualid)
            .then((data) => {
                setShares(data);
                Promise.all(
                    data.filter(d => d.securedcontentID === "" ).map(async (el) => {
                        try {
                            const benId = el.tags?.RequestId ? "" : el.beneficiaryID
                            const result = await GetAddress(
                                el.addressID,
                                benId,
                                el.token,
                                el.individualID
                            );
                            
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddresses((addresses) => [...addresses, ...addrs]);
                    })
                    .catch((e) => {
                        console.log(e);
                        setShowAlert(true);
                        setAlertText("Error fetching data");
                    });
                    Promise.all(
                        data.filter(d => d.securedcontentID !== "" ).map(async (el) => {
                            const benId = el.tags?.RequestId ? "" : el.beneficiaryID

                            try { return await GetSharedSecuredContent(el.securedcontentID, el.token, benId ,el.individualID); 
                            } catch (err) {
                                console.error("Error fetching addresses:", err);
                                setShowAlert(true);
                                setAlertText("Error fetching data");
                            }
                        })
                    )
                        .then((contents) => {
                            setSecuredContents((securedContents) => [...securedContents, ...contents]);
                        })
                        .catch((e) => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        });
            })
            .catch((error) => {
                console.error("Error fetching sharedWith:", error);
                setShowAlert(true);
                setAlertText("Error fetching data");
            });
        getSharesWithBene();    
    }, []);

    const getSharesWithBene = () => {
        const individualid = getInfo("IndividualID");
        const ownertoken = getInfo("Ownertoken")
        GetShares(individualid)
            .then((data) => {
                
                let ben = data.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0);
                setSharesWithBen(ben);
                
                ben.map((b) => {
                        if(b.serviceProviderID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0)
                            GetBeneficiary(b.beneficiaryID).then((el) => setBenList((benList) => ({...benList, [b.beneficiaryID] : el}))).catch((err) => console.log(err))

                    })

                Promise.all(
                    ben.filter(d => d.securedcontentID === "" ).map(async (el) => {
                        try {
                            const result = await GetAddress(
                                el.addressID,
                                "",
                                ownertoken,
                                el.individualID
                            );
                           
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddressesSharedWithBene((addressesSharedWithBene) => [
                            ...addressesSharedWithBene,
                            ...addrs,
                        ]);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                    Promise.all(
                        ben.filter(d => d.securedcontentID !== "" ).map(async (el) => {
                            try { return await GetSecuredContent(el.securedcontentID);
                            } catch (err) {
                                console.error("Error fetching addresses:", err);
                                setShowAlert(true);
                                setAlertText("Error fetching data");
                            }
                        })
                    )
                        .then((contents) => {
                            setSecuredContents((securedContents) => [...securedContents, ...contents]);
                        })
                        .catch((e) => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        });
                
                    
                let sp = data.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") === 0);
                setSpShares(spShares => [...spShares, ...sp]);

                Promise.all(
                    sp.filter(d => d.securedcontentID === "" ).map(async (el) => {

                        try {
                            const result = await GetAddress(
                                el.addressID,
                                "",
                                ownertoken,
                                el.individualID
                            );
                           
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddressesSharedWithSP((addressesSharedWithSP) => [
                            ...addressesSharedWithSP,
                            ...addrs,
                        ]);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                    Promise.all(
                        sp.filter(d => d.securedcontentID !== "" ).map(async (el) => {
                            try { return await GetSecuredContent(el.securedcontentID);
                            } catch (err) {
                                console.error("Error fetching addresses:", err);
                                setShowAlert(true);
                                setAlertText("Error fetching data");
                            }
                        })
                    )
                        .then((contents) => {
                            setSecuredContents((securedContents) => [...securedContents, ...contents]);
                        })
                        .catch((e) => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        });
                sp.map((s) => {
                    try {
                        if (spIds.findIndex((v) => v === s.serviceProviderID) === -1) {
                            spIds.push(s.serviceProviderID)
                            getTemplates(s.serviceProviderID);
                            GetServiceProvider(s.serviceProviderID).then((el) => setSpList((spList) => ({ ...spList, [s.serviceProviderID]: el }))).catch((err) => console.log(err))


                            return GetRequestDetails(s.tags?.RequestId?.Value).then((r) => {
                                const refs = r.tags?.beneficiaryReference?.Value || [];
                                setRNote((rNote) => ({ ...rNote, [s?.tags?.RequestId?.Value]: r.tags?.note?.Value || "" }))
                                setRefBens((refBens) => ({ ...refBens, [s?.tags?.RequestId?.Value]: refs }));
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                    } catch (err) {
                        console.error("Error fetching request details:", err);
                        setShowAlert(true);
                        setAlertText("Error fetching data");
                    }
                })
            })
            .catch((error) => {
                console.error("Error fetching shares:", error);
                setShowAlert(true);
                setAlertText("Error fetching data");
            });
    }

    const deleteServiceProviderShare = () => {
        setShowDeleteDlg(false);
        console.log('deleteSPShare')

        const shareReq = currShare

        DeletePrimaryToken(shareReq.serviceProviderID, shareReq.individualID, shareReq.addressID, shareReq.securedcontentID)
            .then((response) => {
                setShowAlert(true);
                setAlertText("Removed share successfully.");
                const timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                setAddressesSharedWithSP([])
                setAddressesSharedWithBene([])
                setSharesWithBen([]);
                setSpShares([]);
                getSharesWithBene();
            })
            .catch((error) => {
                console.error("Error removing share", error);
                setShowAlert(true);
                setAlertText("Error removing a share.");
            });
    }

    const deleteShare = () => {
        setShowDeleteDlg(false);
        if (sP) { deleteServiceProviderShare(); return; }
        const shareReq = currShare;
        var addressOffered = !shareReq.tags?.RequestId 
        let id = shareReq.serviceProviderID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? (addressOffered ?  shareReq.tags.SharedWithEmail.Value : "")  : shareReq.beneficiaryID;
        
        DeleteSecondaryToken("", id, shareReq.token)
            .then((response) => {
                setShowAlert(true);
                setAlertText("Removed share successfully.");
                const timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                setAddressesSharedWithBene([])
                setAddressesSharedWithSP([])
                setSharesWithBen([]);
                setSpShares([]);
                getSharesWithBene();
            })
            .catch((error) => {
                console.error("Error removing share", error);
                setShowAlert(true);
                setAlertText("Error removing a share.");
                
            });
    }
    const handleDeleteClick = (share, sp) => {
        console.log(addressesSharedWithBene, shares, sp)
        setConfirmationText("Are you certain you wish to Remove the sharing of this address?")
        setShowDeleteDlg(true);
        setCurrShare(share);
        setSP(sp)
    }

    const handleTypeFilter = (e) => {
        const val = e.target.value;
        setContenttype(val);
    }


    const connect = (sp, address, content) => {
        if(address === "") {
            const shareReq = spShares.find((el) => el.securedcontentID === content.id && sp.serviceProviderID === el.serviceProviderID);
            CreateSecondaryToken(shareReq.serviceProviderID, refBens[sp?.tags?.RequestId?.Value][0] , shareReq.token).then((resp) => { 
                setShowAlert(true);
                setAlertText("You have connected with the recommended beneficiary! ");
                const timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                setAddressesSharedWithBene([])
                setAddressesSharedWithSP([])
                setSpShares([])
                getSharesWithBene();
            }).catch((err) => {console.log('error creating secondary token', err)})
        } else {
        console.log('connect ', sp , address);
        const shareReq = spShares.find((el) => el.addressID === address.id && sp.serviceProviderID === el.serviceProviderID);
        CreateSecondaryToken(shareReq.serviceProviderID, refBens[sp?.tags?.RequestId?.Value][0] , shareReq.token).then((resp) => { 
            setShowAlert(true);
            setAlertText("You have connected with the recommended beneficiary! ");
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            setAddressesSharedWithBene([])
            setAddressesSharedWithSP([])
            setSpShares([])
            getSharesWithBene();
        }).catch((err) => {console.log('error creating secondary token', err)})
    }
    }
    
    return userId ? (

        <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
            <h1 className="bold-text-input">Secure Content Shares</h1>
            <DeleteModal confirmationText={confirmationText} deleteLabel="Remove Share" onDeleteFn={deleteShare} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />
            
            <div
                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                role="alert"
                style={{ display: showAlert ? "block" : "none" }}
            >
                <span class="text-sm">{alertText} </span>
            </div>

            
            <div>
            <div className="p-5">
            <select id="contenttype" class="p-2 border font-semibold rounded-lgblock bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" onChange={handleTypeFilter}>
                <option selected value={""}>All Content Types</option>
                {templates.sort((a, b) => {return (a?.Tags?.DisplayName?.Value || a?.Name)?.toLowerCase().localeCompare((b?.Tags?.DisplayName?.Value || b?.Name)?.toLowerCase())}).map(ele => {return <option value={ele?.Name}> {ele?.Tags?.DisplayName?.Value || ele?.Name} </option>}) }
            </select></div>
                <h1 className="p-5 bold-text-input">Content Shared with me</h1>
                <div class=" bg-gray  ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                (contenttype === "Addresses"  || contenttype === "" ) && shares && shares!== undefined && shares.length > 0 ?
                                shares.map((share) => {
                                    var address = addresses.find((ele) => share.addressID === ele?.id)
                                    var from = decodeURIComponent(
                                        share?.tags?.UserEmail ? share.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        share?.tags?.SharedWithEmail ? share.tags.SharedWithEmail.Value : ""
                                    );
                                    var addressOffered = !share.tags?.RequestId 
                                    return <AddressBox created={share?.created ? share.created : ""} updated={share?.updated ? share.updated : ""} address={address} from={from} to={to} sentBadge={addressOffered} />

                                }) : <></>
                            }
                            {
                                contenttype !== "Addresses" && shares && shares!== undefined && shares.length > 0 ?
                                shares.map((share) => {
                                    var content = securedContents.find((ele) => share.securedcontentID === ele?.id)
                                    if(content) {
                                    let matchedtemplate = FirstMatchedTemplate(content, templates);

                                        if(contenttype === "" || contenttype === content?.tags[ProcessTemplate(matchedtemplate).contentTypeTag].Value) {
                                        var from = decodeURIComponent(
                                            share?.tags?.UserEmail ? share.tags.UserEmail.Value : ""
                                        )
                                        var to = decodeURIComponent(
                                            share?.tags?.SharedWithEmail ? share.tags.SharedWithEmail.Value : ""
                                        );
                                        return <ContentBox template={matchedtemplate}  created={share?.created ? share.created : ""} updated={share?.updated ? share.updated : ""} content={content} from={from} to={to} /> } else return <></>
                            } else return <></>}) : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="p-5 bold-text-input">Shared with Service Provider</h1>
                <div class=" bg-gray ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                (contenttype === "Addresses"  || contenttype === "" ) ? 
                                spShares.map((sp) => {
                                    var address = addressesSharedWithSP.find((ele) => sp.addressID === ele?.id)
                                    var bn = refBens[sp?.tags?.RequestId?.Value] || [];
                                    var connected = sharesWithBen.find((el)=> el.serviceProviderID === sp.serviceProviderID && el.addressID === sp.addressID)
                                    var serviceProviderID=sp.serviceProviderID
                                    var from = decodeURIComponent(
                                        sp?.tags?.UserEmail ? sp.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        sp?.tags?.SharedWithEmail ? sp.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                        <AddressBox created={sp?.created ? sp.created : ""} updated={sp?.updated ? sp.updated : ""}  from={from} to={to} benList={benList} connected={connected} sharedTo={spList[sp.serviceProviderID]?.tags?.name?.Value} address={address} onDelete={() => handleDeleteClick(sp, true)} ben={bn} message={rNote[sp?.tags?.RequestId?.Value]} onConnect={() => connect(sp, address)} serviceProviderId={serviceProviderID}></AddressBox>                                        
                                    </>
                                })  : <></>
                            }
                            {
                                contenttype !== "Addresses" ? 
                                spShares.map((sp) => {
                                    var content = securedContents.find((ele) => sp.securedcontentID === ele?.id)
                                    if(content) {
                                    let matchedtemplate = FirstMatchedTemplate(content, templates);
                                    if(contenttype === "" || contenttype === content?.tags[ProcessTemplate(matchedtemplate).contentTypeTag].Value){
                                    var bn = refBens[sp?.tags?.RequestId?.Value] || [];
                                    var connected = sharesWithBen.find((el)=> el.serviceProviderID === sp.serviceProviderID && el.securedcontentID === sp.securedcontentID)
                                    var serviceProviderID=sp.serviceProviderID
                                    var from = decodeURIComponent(
                                        sp?.tags?.UserEmail ? sp.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        sp?.tags?.SharedWithEmail ? sp.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                        <ContentBox template={matchedtemplate} created={sp?.created ? sp.created : ""} updated={sp?.updated ? sp.updated : ""}  from={from} to={to} benList={benList} connected={connected} sharedTo={spList[sp.serviceProviderID]?.tags?.name?.Value} content={content} onDelete={() => handleDeleteClick(sp, true)} ben={bn} message={rNote[sp?.tags?.RequestId?.Value]} onConnect={() => connect(sp, "", content)} serviceProviderId={serviceProviderID}/>
                                    </>
                                    } else return <></> } else return <></> }
                                ) : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="p-5 bold-text-input">Shared with Beneficiary</h1>
                <div class=" bg-gray ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3 gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                (contenttype === "Addresses"  || contenttype === "" ) ? 
                                sharesWithBen.map((ben) => {
                                    var address = addressesSharedWithBene.find((ele) => ben.addressID === ele?.id)
                                    var benId=ben.beneficiaryID
                                    var from = decodeURIComponent(
                                        ben?.tags?.UserEmail ? ben.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        ben?.tags?.SharedWithEmail ? ben.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                    { from !== "" ?
                                        <AddressBox created={ben?.created ? ben.created : ""}  updated={ben?.updated ? ben.updated : ""} from={from} to={to} sharedTo={benList[ben.beneficiaryID]?.tags?.name?.Value} address={address} onDelete={() => handleDeleteClick(ben, false)} benId={benId} token={ben?.token}></AddressBox>
                                        :
                                        <AddressBox created={ben?.created ? ben.created : ""}  updated={ben?.updated ? ben.updated : ""} from={from} to={to} sharedTo={benList[ben.beneficiaryID]?.tags?.name?.Value} address={address} onDelete={() => handleDeleteClick(ben, false)} benId={benId} ></AddressBox>
                                    }
                                    </>
                                }) : <></>
                            }
                            {
                                sharesWithBen.map((ben) => {
                                    var content = securedContents.find((ele) => ben.securedcontentID === ele?.id)
                                    if(content) {
                                    let matchedtemplate = FirstMatchedTemplate(content, templates);
                                    if(content && (contenttype === "" || contenttype === content?.tags[ProcessTemplate(matchedtemplate).contentTypeTag].Value)){
                                    var benId=ben.beneficiaryID
                                    var from = decodeURIComponent(
                                        ben?.tags?.UserEmail ? ben.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        ben?.tags?.SharedWithEmail ? ben.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                        <ContentBox template={matchedtemplate} created={ben?.created ? ben.created : ""}  updated={ben?.updated ? ben.updated : ""} from={from} to={to} sharedTo={benList[ben.beneficiaryID]?.tags?.name?.Value} content={content} onDelete={() => handleDeleteClick(ben, false)} benId={benId} />
                                    </>
                                    } else return <></> } else return<></>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            
           
            
            
        
        </div>

    ) : (
        <Login />
    );
};

export default IndividualShares;
