import {  useState } from "react";
import { BsPlus } from "react-icons/bs";
import { renderField, renderTag } from "../utilities/functions";

export default function Tags({ tags, isReadOnly, saveFn, deleteFn }) {
    const [showModal, setShowModal] = useState();
    const [name, setName] = useState();
    const [value, setValue] = useState();
    const [privateVal, setPrivateVal] = useState();
    const [errText, setErrText] = useState();

    const addTag = () => {
        const tag = { Name: name, Value: value, Required: false, Editable: true, Secure: false, Private: privateVal }
        if(tags && Object.values(tags).length > 0 && Object.values(tags).findIndex(t => t.Name === name) !== -1) {
            setErrText('Tag with name "' + name + '" already exists!')
        }
        else {
            saveFn(tag);
            setName(""); setValue(""); setPrivateVal(false);
            setErrText("");
            setShowModal(false)
        }
    } 

    const editTag = (e, key) => {
        const { type, value, checked, name } = e.target;
        const editedTag = tags[key];
        if (type === "checkbox") editedTag.Private = checked;
        else if (name === "name") { 
            if(Object.values(tags).length > 0 && Object.values(tags).findIndex(t => t.Name === value) !== -1) {
                setErrText('Tag with name "' + value + '" already exists!')
                return;
            } else {
                setErrText("");
                editedTag.Name = value;
            }
        }else editedTag.Value = value;
        saveFn(editedTag, key);
        setErrText("");
    }

    const deleteTag = (e, key) => {
        console.log(key, e)
        setErrText("");
        deleteFn(key);
    }

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "privateVal") setPrivateVal(checked);
        else if (name === "name") setName(value);
        else setValue(value);
    }

    const showTagForm = () => {
        setShowModal(true)
    }

    const cancelTagForm = () => {
        setName(""); setValue(""); setPrivateVal(false);
        setErrText("");
        setShowModal(false)
    }

    return <>
    
    {tags && Object.keys(tags).filter(tag => !tag.startsWith('__SYS_JW')).length > 0? (Object.keys(tags)).filter(tag => { console.log(tag); return !tag.startsWith('__SYS_JW')}).map((key) => {
        return renderTag(tags[key].Name, key, tags[key].Value, tags[key].Private, (e) => editTag(e,key), (e) => deleteTag(e, key), isReadOnly);
    }) : <></>
    }
    {isReadOnly ? <></> :
        showModal ? <div id="tagModal">
            {renderField("Name", "name", "alphanumeric", name, handleInputChange)}
            {renderField("Value", "value", "textarea", value, handleInputChange)}
            {renderField("Private (Only visible to you.)", "privateVal", "checkbox", privateVal, handleInputChange)}
            <p className="text-red-400 pb-2">{errText}</p>
            <button onClick={addTag} type="submit" class="py-2 px-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none bg-blue-500 hover:bg-blue-600 focus:ring-blue-900 gap-3" style={{ "margin-right": "1em" }}>
                Add
            </button>
            <button onClick={cancelTagForm} type="button" class="py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:text-white hover:bg-gray-600 focus:ring-gray-600">
                Cancel
            </button>
        </div>
            : <>                <p className="text-red-400 pb-2">{errText}</p>
                <button onClick={showTagForm} type="submit" className="button-style mt-2 px-1 hover:bg-555"><BsPlus className="inline-icon" /> Add More </button>
            </>
    }</>


}