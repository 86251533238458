import { useNavigate,Link , useLocation} from "react-router-dom";
import {  AiOutlineInfoCircle, AiOutlineProfile } from "react-icons/ai";
import { BsFillTrash3Fill} from "react-icons/bs";
import { displayDate } from "../utilities/functions";
import {  ProcessTemplate } from "../utilities/templates";
import DropdownBenSearch from "./DropdownSearchBen";


export default function ContentBox({template, content, onDelete, ben, message, onConnect, sharedTo, benList, connected,serviceProviderId,benId, sentBadge, from, to, created, updated, requestId, isBusiness }) {
    const navigate=useNavigate();
    const { pathname } = useLocation();

    const fetchServiceProviderDetails=(serviceProviderID)=>{
      if(serviceProviderID !== null && serviceProviderID !== undefined){
          navigate(`/AddServiceProviderProfile/${serviceProviderID}`,{ state: { readOnly:true,showPrivate:false,previousPage: pathname } })
        }
    }
    const fetchBenDetails=(benId)=>{
      if(benId !== null && benId !== undefined){
        navigate(`/AddBeneficiaryProfile/${benId}`,{ state: { readOnly:true,showPrivate:false,previousPage: pathname } })
      }
    }

    const fetchBeneficiaryDetails = (benId) => {
      console.log("The Ben Id is : ", benId, benList)
      if (benId !== null && benId !== undefined) {
          navigate(`/AddBeneficiaryProfile/${benId}`, { state: { readOnly: true, showPrivate: true, previousPage: pathname } })
      }
    }

    const templatedetails = ProcessTemplate(template);
//  { contentTypeTag, templateName: templatename, contentNameTag, tags: template.Tags }

    const getFields = () => {
      let fields = [];
   	  if (templatedetails?.tags) {
        fields = templatedetails?.tags;
      } 
      return fields
    }


    return (
      content ?
        <div className="relative md-container border border-gray-500 bg-gray-800 rounded m-1 pt-6 pb-12 px-3" style={{ "overflow-wrap": "break-word" }}>
          <div className="flex flex-row justify-between">
            <p className="font-bold leading-6 text-white flex flex-row justify-around">
            <Link class="font-semibold truncate cursor-pointer" to={`/addsecurecontent/${content.id}`} state={{"contenttype": templatedetails.templateName, "template": template, "readonly": true, "previousPage": pathname, "content": content}} >
            {content?.tags[templatedetails.contentNameTag]?.Value}</Link>
              </p>
            <p>
              {onDelete ?
                <button formMethod="dialog" formTarget="top" id="deleterequest" className="bg-slate-333 py-1 hover:bg-555" onClick={onDelete}>
                  <BsFillTrash3Fill title="Delete" className="inline-icon" />
                </button>
                : <></>}
            </p>
          </div>
          <p><span class="text-xs font-medium me-2 text-blue-300 "><AiOutlineProfile className='inline-icon' style={{height: "1rem" , width: "1rem" }} />{templatedetails.templateDisplayName}</span>          </p>
          <p>{        sentBadge      ?                <span class="text-xs text-center px-1 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400">content Sent</span>:<></>}
            </p>
          <p className="mt-1 text-xs text-gray-400">{ updated ? "Updated " + displayDate(updated) : ""}  </p>  
          {updated && created && updated !== created ? <p className="text-xs text-gray-400">{created ? "Created " + displayDate(created) : ""}</p>: <></>}
          { Object.values(getFields()).map((key) => { return  <p className="mt-2 truncate text-sm leading-5 text-gray-100">
            <span className="italic text-xs">{key.Name ? key.Name + ":" : ""} </span> <span> {(key.Name !== templatedetails.contentNameTag ?  content.tags[key.Name]?.Value : "" )} </span> 
          </p>
            })}
         {from && from > 0 ?
            <p className="mt-2 leading-6 text-gray-100">
              <span className="text-xs ">Shared By: </span>
              <span className="text-xs  text-blue-400">{from}</span>
            </p> : <></>
          }
          {to && to.length > 0 ?
            <p className=" leading-6 text-gray-100">
              <span className="text-xs">Shared With: </span>
              <span className="text-xs text-blue-400">{to}</span>
            </p> : <></>
          }
          {sharedTo && sharedTo.length > 0 ?
            <p className=" leading-6 text-gray-100">
              <span className="text-xs">Shared With: </span>
              {
                ben ? (<button className="text-xs text-blue-400" onClick={()=>fetchServiceProviderDetails(serviceProviderId)}>{sharedTo}</button>)
                : (<button className="text-xs text-blue-400" onClick={()=>fetchBenDetails(benId)}>{sharedTo}</button>)
              }
              </p> : <></>
          }
           {
          requestId && ben.length > 0 ? (<div class="w-full mt-2 p-4 rounded-lg shadow-sm border bg-gray-800 border-gray-600 text-blue-300" role="alert">
          <div class="flex">
            <p class=" text-sm">Your recommendation was accepted. <span class="text-blue-400">{ben.map((b) => { return <button onClick={() => fetchBeneficiaryDetails(b.beneficiaryID)}>{"  " + benList.find(bn => bn.id === b.beneficiaryID)?.tags?.name?.Value + " "}</button> })} (Beneficiary)</span> is now a connection!</p></div></div>
          ) :      ( requestId ?  <DropdownBenSearch requestId={requestId} benList={benList} /> : <></>)
        } 
          {
            !isBusiness && ben ?
              ben.map((id) => {
                
                return <div class="w-full mt-2 p-4 rounded-lg shadow-sm border bg-gray-800 border-gray-600 text-blue-300" role="alert">
                  <div class="flex">
                    { connected ? <div class="py-2 text-sm "> <span className="font-semibold ">Great! </span><div>You are connected with {benList[id]?.tags?.name?.Value + " (beneficiary)"} recommended by {sharedTo + " (Service Provider)"}.</div></div>
                    :
                    <div class="ms-1 text-sm font-normal">
                      <span class="mb-1 text-sm font-semibold text-white"><AiOutlineInfoCircle className="inline-icon"/> Recommendation! </span>
                      <div class="py-2 text-xs">{sharedTo} has referred {benList[id]?.tags?.name?.Value} to you.</div>
                      <blockquote class="text-xs italic font-semibold text-white">
                        <p>{ message && message.length > 0 ? '"' + message + '"' : <></>}</p>
                      </blockquote>
                      <div class="grid grid-cols-2 gap-2 p-2">
                        <div>
                          <Link to={`/AddBeneficiaryProfile/${id}`} state={{ readOnly: true, showPrivate: false, previousPage: pathname }} >
                            <button class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-800">View</button></Link>
                        </div>
                        <div>
                          <button onClick={onConnect} class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-800">Connect</button>
                        </div>
                      </div>
                    </div>
                  }
                  </div>
                </div>
  
  
              }) : <></>
          }
        </div>
        : <></>);
  }