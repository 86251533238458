import React, { useEffect, useState } from 'react';
import { CgTemplate } from 'react-icons/cg';
import { renderField } from "../utilities/functions";
import { getInfo, CreateTemplate, UpdateTemplate, GetTemplateByID} from '../utilities/calls';
import { Link, useLocation, useParams } from 'react-router-dom';
import Tags from './Tags';
export default function AddTemplate() {
  let { state } = useLocation();
  const spid = state?.spid || "";

  const [template, setTemplate] = useState({ Name: "", URL: "", HeaderName: "", DisplayName: "", TypeName: ""});
  const [sent, setSent] = useState(true);
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [customTags, setCustomTags] = useState([])
  const { id } = useParams(); 
  

  useEffect(() => {
    if(id !== undefined) {
    GetTemplateByID(id).then((resp) => {
      
      var tags = {}
      Object.keys(resp?.Tags)?.map((tag) => {
        var name = resp?.Tags[tag]?.Name;
        if(name === "HeaderName") {
          resp.HeaderName = resp.Tags[tag].Value;
        } else if(name === "DisplayName") {
          resp.DisplayName = resp.Tags[tag].Value;
        } else if(name === "TypeName") {
          resp.TypeName = resp.Tags[tag].Value;
        } else 
          tags[name] = resp.Tags[tag];
      })
      setCustomTags(tags);
      setTemplate(resp);
    }).catch((err) => {console.log('Error getting content', id)})
  }
  },[])


  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setTemplate((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setSent(false);
  };
  function handleAccordionClick(ev) {
    let dv, icon;
    if (ev.target.dataset && ev.target.dataset.accordionTarget) {
      dv = document.getElementById(ev.target.dataset.accordionTarget);
      icon = ev.target.querySelector("svg");
    } else {
      const button = ev.target.closest("button");
      dv = document.getElementById(button.dataset.accordionTarget);
      icon = button.querySelector("svg");
    }
    if (dv.classList.contains("hidden")) {
      dv.classList.add("block");
      dv.classList.remove("hidden");
      icon.classList.remove("rotate-180");
    } else {
      dv.classList.add("hidden");
      dv.classList.remove("block");
      icon.classList.add("rotate-180");
    }
  }
  const addCustomTag = (tag,key) => {
    var tagname = key ? key : tag.Name;
    setCustomTags((prevValues) => ({
      ...prevValues, [tagname]: tag
    }))
    setSent(false);
  }

  const removeCustomTag = (tagName) => {
    var newMap = {...customTags};
    delete newMap[tagName]
    setCustomTags({...newMap} );
    setSent(false);
  }

  const getMergedData = () => {
   
    template.Tags = {};
    //Object.keys(template).forEach(function (key) { mergedData.tags[key] = { Name: key, Value: template[key], Private: false, Required: false, Editable: false } })
    return template;
  }


  const save = (mergedData) => {
    const individualid = getInfo("IndividualID");
    mergedData.individualid = individualid;
    mergedData.id = id;
    mergedData.serviceproviderid = spid;
    UpdateTemplate(id, mergedData)
      .then((response) => {
        setShowAlert(true);
        setAlertText("saved successfully!");
        setSent(true);
      })
      .catch((error) => {
        console.error("Error updating template:", error);
        setShowAlert(true);
        setAlertText("Updating a template failed, please try again!");
      });
  }

  const add = (mergedData) => {
    const individualid = getInfo("IndividualID");
    mergedData.individualid = individualid;
    mergedData.serviceproviderid = spid;
    CreateTemplate(mergedData).then((r) => {
      setShowAlert(true);
      setAlertText("Data added!");
      setSent(true);
    })
      .catch((error) => {
        console.error("Error adding template:", error);
        setShowAlert(true);
        setAlertText("Adding a template failed.");
      });

  }


  const processForm = () => {
    var mergedData = getMergedData();
    mergedData.Tags = { ...mergedData.Tags, ...customTags }
    if (template.HeaderName && template.HeaderName.trim().length > 0) {
      mergedData.Tags.HeaderName = {
        Name: "HeaderName",
        Value: template.HeaderName,
        Private: false,
        Required: false,
        Editable: false
      }
    }
    if (template.DisplayName && template.DisplayName.trim().length > 0) {
      mergedData.Tags.DisplayName = {
        Name: "DisplayName",
        Value: template.DisplayName,
        Private: false,
        Required: false,
        Editable: false
      }
    }
    if (template.TypeName && template.TypeName.trim().length > 0) {
      mergedData.Tags.TypeName = {
        Name: "TypeName",
        Value: template.TypeName,
        Private: false,
        Required: false,
        Editable: false
      }
    }
    if (id)
      save(mergedData);
    else {
      add(mergedData)
    }
  }

  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="form-label">
            <CgTemplate className="inline-icon" />
            Add Template
          </h1>
        </div>
        <div id="accordion-collapse" data-accordion="collapse" className="p-5">
          <h2 id="accordion-collapse-heading-1">
            <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
              <span>Template Details </span>
              <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
            <div class="p-5 border border-grey-700">
              <fieldset>
                {renderField("Template Name", "Name", "alphanumeric", template.Name, handleInputChange)}
                {renderField("Template Display Name", "DisplayName", "alphanumeric", template.DisplayName, handleInputChange)}
                {renderField("URL", "URL", "alphanumeric" , template.URL, handleInputChange)}
                {renderField("Secure Content Header Field", "HeaderName", "alphanumeric", template.HeaderName, handleInputChange)}
                {renderField("Secure Content type Field", "TypeName","alphanumeric", template.TypeName, handleInputChange )}

              </fieldset>
            </div>
          </div>
          <h2 id="accordion-collapse-heading-3">
            <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border gap-3" data-accordion-target="accordion-collapse-body-3" onClick={handleAccordionClick}>
              <span>More Details </span>
              <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
            <div class="p-5 border border-grey-700">
              <fieldset >

                <div className="py-5"><Tags isReadOnly={false} saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag} /></div>

              </fieldset>
            </div>

          </div>


        </div>
        <section className="flex flex-start gap-2 py-2">
          <button style={{ width: "fit-content", display: sent ? "none" : "block", }} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="button-style positive-action">
            {id ? "UPDATE" : "ADD"}
          </button>

          {!sent ? (
            <Link className="  button-style" to={state?.previousPage? state.previousPage : "/serviceprovidertemplates/"+spid} >Cancel</Link>
          ) : (
            <Link className="  button-style" to={state?.previousPage? state.previousPage :"/serviceprovidertemplates/"+spid}>Close</Link>
          )}
          <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }}>
            <span class="text-sm">{alertText} </span>
          </div>
        </section>
              </div>
    </div>
  )
}

